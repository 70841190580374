import * as React from "react"
import Drive from "../../components/Drive/drive"
import Seo from "../../components/seo"
import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"

const DrivePage = props => {
  let searchUrl = props.location.search
  let addDoc = searchUrl.indexOf("addDoc") === 1
  let addFolder = searchUrl.indexOf("addFolder") === 1
  let addForm = searchUrl.indexOf("addForm") === 1
  let addProduct = searchUrl.indexOf("addProduct") === 1
  let addService = searchUrl.indexOf("addService") === 1
  let addSequence = searchUrl.indexOf("addSequence") === 1
  let addChatBot = searchUrl.indexOf("addChatBot") === 1
  let addCode = searchUrl.indexOf("addCode") === 1

  return (
    <React.Fragment>
      <Seo title="Drive" />
      <PrivateRoute
        path="/"
        component={Drive}
        location={props.location}
        addProduct={addProduct}
        addDoc={addDoc}
        addForm={addForm}
        addService={addService}
        addChatBot={addChatBot}
        addSequence={addSequence}
        addCode={addCode}
        addFolder={addFolder}
        status={1}
      />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<DrivePage {...props} />} />
export default WrappedPage
